import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { LOBBYIDLIST } from 'common/constants/keys';
//common
import { ROUTES } from 'common/constants/routing';
import useLoadThirdPartyScript from 'services/hook/useLoadThirdPartyScript';

//components
import LayoutRoute from './LayoutRoute';
import Layout from '../layouts/Layout';
import ProtectedLayout from '../layouts/ProtectedLayout';
import { BrandRoutes } from 'component/mobile/app/routes/BrandRoutes';
import TSBWidget from 'component/mobile/home/tsbwidget/TSBWidget';
import Vault from 'component/mobile/wallet/vault/Vault';
import { useMyHistory } from 'component/shared/provider/HistoryProvider';

const {
  Home,
  ErrorPage,
  NotFound,
  Login,
  Logout,
  Register,
  MemberCenter,
  Deposit,
  Verify,
  HomeGameLobby,
  Transaction,
  ForgotPassword,
  ResetPassword,
  MailConfirmation,
  CreateAccount,
  Withdrawal,
  GameCat,
  Affiliate2,
  AffiliateDetails,
  Partner,
  Bonus,
  BonusDetails,
  Vip,
  MyVip,
  Policy,
  Maintenance,
  TermService,
  DataDeletion,
  UserAgreement,
  Favourite,
  Recent,
  Intercom,
  Installation,
  Providers,
  // LuckyWheel,
  JiliEvent,
  Unsubscribe,
  // FreeSpin,
  Voucher,
  ReferralBonus,
  LiveSupport,
} = BrandRoutes;

const AppRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { push } = useMyHistory();
  useLoadThirdPartyScript();

  useEffect(() => {
    push(location.pathname);
  }, [location, push]);

  const ScrollToTop = ({ children }) => {
    useEffect(() => {
      //fix for topbar and footer flicking issue when inital to page without scrolling
      window.scrollTo(0, 5);
    }, []);
    return children;
  };

  return (
    <ScrollToTop>
      <Routes>
        {/* layout primary show wallet and menu */}
        <Route element={<Layout isPrimary={true} showCloseButton={false} />}>
          <Route path="*" element={<NotFound />} />

          <Route path={ROUTES.HOME} element={<Home />} />
          <Route path={ROUTES.ERROR} element={<ErrorPage />} />
          <Route path={ROUTES.ACCOUNT.LOGOUT} element={<Logout />} />

          <Route element={<LayoutRoute privateRoute={true} />}>
            <Route path={ROUTES.TRANSACTION.ROOT} element={<Transaction />} />
            <Route path={ROUTES.USER.MEMBER_CENTER.ROOT} element={<MemberCenter />} />
            <Route path={ROUTES.FAVOURITE.ROOT} element={<Favourite />} />
            <Route path={ROUTES.RECENT.ROOT} element={<Recent />} />
            <Route path={ROUTES.AFFILIATE.ROOT} element={<Affiliate2 />} />
          </Route>
          <Route path={ROUTES.ACCOUNT.VERIFY} element={<Verify />} />
          <Route path={ROUTES.SLOT.HOME} element={<HomeGameLobby lobbyId={LOBBYIDLIST.Slots} />} />
          <Route path={ROUTES.ACCOUNT.UNSUBSCRIBE} element={<Unsubscribe />} />
          <Route path={ROUTES.POKER.ROOT} element={<HomeGameLobby lobbyId={LOBBYIDLIST.Poker} />} />
          <Route
            path={ROUTES.ORIGINAL.ROOT}
            element={<HomeGameLobby lobbyId={LOBBYIDLIST.Original} />}
          />

          <Route
            path={ROUTES.CASINO.ROOT}
            element={<HomeGameLobby lobbyId={LOBBYIDLIST.LiveCasino} />}
          />
          <Route
            path={ROUTES.SPORTS.ROOT}
            element={<HomeGameLobby lobbyId={LOBBYIDLIST.Sports} />}
          />
          <Route path={ROUTES.GAME.GAMECAT} element={<GameCat />} />
          {/* <Route path={ROUTES.AFFILIATE.ROOT} element={<Affiliate />} /> */}
          <Route path={ROUTES.AFFILIATE.PARTNER} element={<Partner />} />

          <Route path={ROUTES.BONUS.ROOT} element={<Bonus />} />

          <Route path={ROUTES.USER.VIP.ROOT} element={<Vip />} />
          <Route path={ROUTES.PROVIDERS.ROOT} element={<Providers />} />
        </Route>

        <Route
          element={<Layout isPrimary={true} handleCloseGame={() => navigate(-1)} isSports={true} />}
        >
          <Route element={<LayoutRoute privateRoute={true} />}>
            <Route
              path={ROUTES.TSBSPORTS.ROOT}
              element={<TSBWidget sportCat={location.search.slice(9)} />}
            />
          </Route>
        </Route>
        <Route element={<Layout isPrimary={true} hasLeftBack={true} />}>
          <Route element={<LayoutRoute privateRoute={true} />}>
            <Route path={ROUTES.WALLET.DEPOSIT + '/:id?'} element={<Deposit />} />
            <Route path={ROUTES.WALLET.WITHDRAWAL} element={<Withdrawal />} />
            <Route path={ROUTES.WALLET.VAULT} element={<Vault />} />
          </Route>
        </Route>
        {/* layout secondary back button on left,no snackbar*/}
        <Route element={<Layout showCloseButton={false} snackBar={false} />}>
          <Route element={<LayoutRoute privateRoute={true} />}>
            <Route path={ROUTES.AFFILIATE.DETAILS} element={<AffiliateDetails />} />
            <Route path={ROUTES.USER.VIP.MYVIP} element={<MyVip />} />
            <Route path={ROUTES.BONUS.VOUCHER} element={<Voucher />} />
          </Route>
        </Route>
        {/* layout secondary back button on left,no logo,no snackbar*/}
        <Route element={<Layout showCloseButton={false} noPrimaryLogo={true} snackBar={false} />}>
          <Route element={<LayoutRoute privateRoute={true} />}>
            <Route path={ROUTES.REFERRAL_PROMOTION.ROOT} element={<ReferralBonus />} />
          </Route>
        </Route>

        {/* layout secondary Logo Left */}
        <Route element={<Layout showCloseButton={true} logoLeft={true} />}>
          <Route path={ROUTES.BONUS.READ} element={<BonusDetails />} />
          <Route path={ROUTES.APP_INSTALATION.ROOT} element={<Installation />} />
          {/* <Route path={ROUTES.USER.LUCKY_WHEEL.ROOT} element={<LuckyWheel />} /> */}
          {/* <Route path={ROUTES.USER.FREE_SPIN.ROOT} element={<FreeSpin />} /> */}
          {/* <Route path={ROUTES.USER.CPF_BONUS.ROOT} element={<CPFBonus />} /> */}
        </Route>
        <Route
          element={
            <Layout
              showCloseButton={true}
              logoLeft={true}
              dynamicScreen={true}
              event={true}
              snackBar={false}
            />
          }
        >
          <Route path={ROUTES.EVENT.JILI} element={<JiliEvent />} />
          <Route path={ROUTES.LIVE_SUPPORT.ROOT} element={<LiveSupport />} />
        </Route>
        {/* layout third dynamicHeight show header background */}
        {/* <Route
          element={
            <Layout isPrimary={true} showCloseButton={true} dynamicHeight={true} showBg={true} />
          }
        >
          <Route path={ROUTES.BONUS.READ} element={<BonusDetails />} />
          <Route path={ROUTES.APP_INSTALATION.ROOT} element={<Installation />} />
        </Route> */}

        {/* protected route layout */}
        <Route element={<ProtectedLayout />}>
          <Route element={<LayoutRoute protectedRoute={true} />}>
            <Route path={ROUTES.ACCOUNT.LOGIN} element={<Login />} />
            <Route path={ROUTES.ACCOUNT.REGISTER} element={<Register />} />
            <Route path={ROUTES.ACCOUNT.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={ROUTES.ACCOUNT.RESET_PASSWORD} element={<ResetPassword />} />
            <Route path={ROUTES.ACCOUNT.MAIL_CONFIRMATION} element={<MailConfirmation />} />
            <Route path={ROUTES.ACCOUNT.CREATE_ACCOUNT} element={<CreateAccount />} />
          </Route>
        </Route>
        <Route path={ROUTES.POLICY.ROOT} element={<Policy />} />
        <Route path={ROUTES.TERMSERVICE.ROOT} element={<TermService />} />
        <Route path={ROUTES.DATADELETION.ROOT} element={<DataDeletion />} />
        <Route path={ROUTES.USERAGREEMENT.ROOT} element={<UserAgreement />} />
        <Route path={ROUTES.MAINTENANCE.ROOT} element={<Maintenance />} />
        <Route path={ROUTES.INTERCOM.ROOT} element={<Intercom />} />
      </Routes>
    </ScrollToTop>
  );
};

export default AppRoutes;
