import React, { useState, useCallback, useEffect, useContext } from 'react';
import { MemberBalanceContext } from 'component/shared/provider/MemberBalanceProvider';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import VaultPromptPassword from './VaultPromptPassword';
import CustomTab from 'component/shared/tabs/CustomTab';
import { DEPOSIT_WITHDRAW_TAB } from 'common/constants/tabs';
import { useGetBalance, useSubmitVault } from 'services/walletService';
import { silenceError } from 'common/helpers/helper';
import VaultTransfer from './VaultTransfer';
import { useLoading } from 'component/shared/provider/LoadingProvider';
import IconHistory from 'component/mobile/common/icon/IconHistory';
import styles from './Vault.module.css';
import VaultHistory from './VaultHistory';
const initFormData = {
  amount: 0,
  password: '',
};
const Vault = () => {
  const { setMemberBalance } = useContext(MemberBalanceContext);
  const navigate = useNavigate();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading } = useLoading();
  const [showPasswordPrompt, setShowPasswordPrompt] = useState(true);
  const [lock, setLock] = useState(false);
  const [bindingTab, setBindingTab] = useState('deposit');
  const [formData, setFormData] = useState({
    ...initFormData,
  });
  const [balanceData, setBalanceData] = useState(null);
  const setSnackbar = useCallback(
    (msg, variant = 'error') => {
      enqueueSnackbar(msg, {
        variant: variant,
        autoHideDuration: 2500,
      });
    },
    [enqueueSnackbar],
  );
  const [{ data: dataSubmit, loading: loadingSubmit, error: errorSubmit }, submit] =
    useSubmitVault();
  useEffect(() => {
    if (dataSubmit) {
      setLock(false);

      if (dataSubmit.result === 0) setSnackbar(dataSubmit.errorMessage);
      if (dataSubmit.result === 1) {
        setSnackbar('Successfully Transferred', 'success');
        setFormData(initFormData);
        if (typeof dataSubmit.balance !== 'undefined') {
          setBalanceData(dataSubmit.balance);
          if (typeof dataSubmit.balance.truncatedBalance !== 'undefined')
            setMemberBalance(dataSubmit.balance.truncatedBalance);
        }
      }
    }
  }, [dataSubmit, setSnackbar]);
  useEffect(() => {
    isLoading('vaultTransfer', loadingSubmit);
  }, [loadingSubmit, isLoading]);

  const [{ data: balanceDataAPI }] = useGetBalance();
  useEffect(() => {
    if (balanceDataAPI) setBalanceData(balanceDataAPI);
  }, [balanceDataAPI]);

  const handleBindingTabChange = useCallback((e, newTab) => {
    if (newTab) {
      setBindingTab(newTab);
      setFormData(initFormData);
    }
  }, []);

  const handleSubmit = useCallback(() => {
    if (!lock) {
      setLock(true);
      silenceError(
        submit({
          data:
            bindingTab === 'deposit'
              ? {
                  amount: parseFloat(formData.amount),
                  type: 0,
                }
              : {
                  amount: parseFloat(formData.amount),
                  password: formData.password,
                  type: 1,
                },
        }),
      );
    }
  }, [enqueueSnackbar, lock, submit, intl, formData]);

  const handleChange = useCallback(
    (prop) => (e) => {
      setFormData((d) => ({
        ...d,
        [prop]: e.target.value,
      }));
    },
    [],
  );
  const setAll = (deposit = true) => {
    setFormData((d) => ({
      ...d,
      amount: deposit ? balanceData.truncatedBalance : balanceData.truncatedVaultBalance,
    }));
  };
  return (
    <>
      <VaultPromptPassword
        navigate={navigate}
        setSnackbar={setSnackbar}
        showPasswordPrompt={showPasswordPrompt}
        setShowPasswordPrompt={setShowPasswordPrompt}
        intl={intl}
      />

      {!showPasswordPrompt && (
        <>
          <div className={styles.tabContainer}>
            <div className={styles.customTab}>
              <CustomTab
                tab={DEPOSIT_WITHDRAW_TAB}
                tabValue={bindingTab}
                handleTabChange={handleBindingTabChange}
              />
            </div>
          </div>

          {bindingTab && bindingTab !== 'history' && (
            <VaultTransfer
              balanceData={balanceData}
              intl={intl}
              handleSubmit={handleSubmit}
              formData={formData}
              handleChange={handleChange}
              setAll={setAll}
              bindingTab={bindingTab}
            />
          )}
          {bindingTab && bindingTab === 'history' && (
            <VaultHistory enqueueSnackbar={enqueueSnackbar} intl={intl} />
          )}
        </>
      )}
    </>
  );
};

export default Vault;
